import React from 'react';
import styled from 'styled-components';

import { boldFontFamily, mobileSize } from '../components/base_styles';
import Button from '../components/Button';
import Layout from '../components/layout/Layout';
import SEO from '../components/Seo';
import InstagramImg from '../images/instagram.svg';
import LinkedinImg from '../images/linkedin.svg';

const IntroWrapper = styled.div`
  background: #5FDECF;
  padding: 135px 0 354px 0;

  @media (max-width: ${mobileSize}) {
    padding: 48px 0;
  }
`;

const Intro = styled.div`
  margin: 0 auto;
  max-width: 954px;
  padding: 0 18px;
`;

const IntroHeading = styled.h1`
  color: white;
  font-family: ${boldFontFamily};
  font-size: 64px;
  line-height: 72px;

  @media (max-width: ${mobileSize}) {
    font-size: 40px;
    line-height: 40px;
  }
`;

const Contact = styled.div`
  background: white;
  box-shadow: 32px 32px 0px #FCE8E4;
  margin: 0 auto 112px auto;
  margin-top: -284px;
  max-width: 826px;
  padding: 96px 64px;

  @media (max-width: ${mobileSize}) {
    box-shadow: none;
    flex-direction: column;
    margin: 0 auto 40px auto;
    padding: 48px 18px;
  }
`;

const ContactTextWrapper = styled.div`
  p {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 40px;
  }

  a {
    color: white;
  }
`;

const Address = styled.div`
  border-top: 1px solid #BCBCBC;
  display: flex;
  margin-top: 48px;
  padding-top: 40px;

  h2 {
    color: #454545;
    font-family: ${boldFontFamily};
    font-size: 16px;
    line-height: 22px;
    text-transform: none;
  }

  @media (max-width: ${mobileSize}) {
    flex-direction: column;
    margin-top: 40px;
    padding-top: 24px;
  }
`;

const AddressSection = styled.div`
  width: 30%;

  @media (max-width: ${mobileSize}) {
    margin-top: 24px;
    width: 100%;
  }
`;

const AddressImg = styled.img`
  margin: 8px 8px 0 0;
`;

const PhoneLink = styled.a`
  cursor: pointer;
  text-decoration: none;
`;

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <IntroWrapper>
      <Intro>
        <IntroHeading>Say hello...</IntroHeading>
      </Intro>
    </IntroWrapper>
    <Contact>
      <ContactTextWrapper>
        <p>
          If you have any questions about my work, a project to discuss or if you’d just
          like to say a simple “hello” then please get in touch.
        </p>
        <Button link="mailto:hello@beckyalford.co.uk" text="Email me" />
      </ContactTextWrapper>
      <Address>
        <AddressSection>
          <h2>Location</h2>
          <p>
            Brighton
            <br />
            East Sussex
            <br />
            United Kingdom
          </p>
        </AddressSection>
        <AddressSection>
          <h2>Call me</h2>
          <PhoneLink href="tel:+447413662604">07413662604</PhoneLink>
        </AddressSection>
        <AddressSection>
          <h2>Follow me</h2>
          <a href="https://www.linkedin.com/in/becky-alford-7934b050/">
            <AddressImg src={LinkedinImg} alt="Linkedin" />
          </a>
          <a href="https://www.instagram.com/beckyalford_designs/">
            <AddressImg src={InstagramImg} alt="Instagram" />
          </a>
        </AddressSection>
      </Address>
    </Contact>
  </Layout>
);

export default ContactPage;
