import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { semiBoldFontFamily } from '../base_styles';

const HrefButton = styled.a`
  background: #5FDECF;
  border-radius: 50px;
  box-shadow: 0 1px 1px rgba(0,0,0,0.1);
  color: #454545;
  display: inline-block;
  font-family: ${semiBoldFontFamily};
  font-size: 16px;
  letter-spacing: 1.5px;
  line-height: 20px;
  padding: 16px 50px;
  text-decoration: none;
  text-transform: uppercase;
  transition: background 0.2s ease-in-out;

  &:hover {
    background: #57D0C2;
  }
`;

const Button = ({ link, text }) => (
  <HrefButton href={link}>
    { text }
  </HrefButton>
);

Button.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Button;
